/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  authMode: ['providers', 'local'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      [
        {
          realm: 'Axima',
          clientId: 'vi-axima-staging',
          buttonText: 'Connexion Axima',
        },
      ],
    ],
  ],
  authLocalOptions: {
    mode: 'otp',
  },
}
