var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-block__pre-heading",class:{ 'ml-4 mr-4 mt-3': _vm.$voicer.isMobile }},[(_vm.isHomeActive)?_c('v-container',{staticClass:"pa-0"},[_c('v-sheet',{ref:"sheet",staticClass:"pa-2",class:{
        'v-sheet__desktop': _vm.$voicer.isDesktopOrTablet,
        'ma-3 mb-4': _vm.$voicer.isDesktopOrTablet,
      },attrs:{"color":"primary"}},[(_vm.isAuthorized)?_c('div',{staticClass:"text-alternate",class:{
          'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[_c('div',{staticClass:"pl-2 pr-2 pt-1 white--text"},[_vm._v("\n          Bienvenue sur Radio Frigo, votre nouveau média interne !\n\n          "),_c('br'),_vm._v("\n          Rendez-vous tous les deux mois pour découvrir toutes les dernières\n          actus 100% dédiées aux équipes d’Axima Réfrigération\n        ")]),_vm._v(" "),_c('div',{staticClass:"toto"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"accent"},on:{"click":function($event){$event.stopPropagation();_vm.showFirstFeedbackModal = true}}},[_vm._v("\n            Écris nous un message\n          ")]),_vm._v(" "),_c('w-feedback-popup',{attrs:{"id":"firstFeedback"},model:{value:(_vm.showFirstFeedbackModal),callback:function ($$v) {_vm.showFirstFeedbackModal=$$v},expression:"showFirstFeedbackModal"}}),_vm._v(" "),_c('v-btn',{staticClass:"white--text",attrs:{"color":"accent"},on:{"click":function($event){$event.stopPropagation();_vm.showSecondFeedbackModal = true}}},[_vm._v("\n            Laisse une dédicace\n          ")]),_vm._v(" "),_c('w-feedback-popup',{attrs:{"id":"secondFeedback"},model:{value:(_vm.showSecondFeedbackModal),callback:function ($$v) {_vm.showSecondFeedbackModal=$$v},expression:"showSecondFeedbackModal"}})],1)]):_vm._e(),_vm._v(" "),(!_vm.isAuthorized)?_c('div',{staticClass:"text-alternate",class:{
          'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[_vm._v("\n        Vous n'avez pas accès à Radio Frigo. Sa diffusion est limitée à\n        l'heure actuelle.\n      ")]):_vm._e()])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }